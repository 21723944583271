<template>
  <div class="w-full h-auto">
    <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <header class="
        mb-4
        flex flex-col-reverse
        items-center
        justify-center
        lg:flex-row lg:justify-between
        gap-3
      ">
      <div class="w-full md:max-w-sm grid grid-cols-1 lg:grid-cols-2 gap-4" v-if="idCompany === 1">
        <a-button type="primary" shape="round" :block="true" class="w-64" @click="goToReportCreation">
          <span class="flex items-start justify-start font-sans text-xs">
            <a-icon type="plus" :style="{ fontSize: '16px' }" class="mr-2" />
            <b>Nuevo informe anual</b>
          </span>
        </a-button>
      </div>
      <div class="w-full sm:max-w-sm">
        <label for="table-search" class="sr-only">Search</label>
        <div class="relative">
          <div class="
              flex
              absolute
              inset-y-0
              left-0
              items-center
              pl-3
              pointer-events-none
            ">
            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"></path>
            </svg>
          </div>
          <a-input-search placeholder="Buscar un usuario en particular" class="rounded-full" style="width: 100%"
            @search="onSearch" v-model="filterSearch" />
        </div>
      </div>
    </header>
    <div class="bg-white overflow-x-auto rounded-xl border border-gray-300 mx-4 mt-4 search-complaints">
      <a-table :columns="columns" :data-source="dataRows" :pagination="false" :scroll="{ x: '1220px' }">
        <div slot="key" class="title-size font-bold text-gray-500 text-center ">
          <p>Código de registro</p>
        </div>
        <div slot="fecha_reporte" class="title-size font-bold text-gray-500 text-center ">
          <p>Fecha de reporte</p>
        </div>
        <div slot="entidad_vigilada" class="title-size font-bold text-gray-500">
          Entidad vigilada
        </div>
        <div slot="responsable" class="title-size font-bold text-gray-500">
          Responsable
        </div>
        <div slot="tiempo" class="flex flex-row justify-items-center title-size font-bold text-gray-500">
          Tiempo
        </div>
        <div slot="tiempo" slot-scope="text, record" class="flex flex-row">
          <p class="w-full rounded-full text-center"
            :class="record.tiempo == 'Óptimo' ? 'text-green-500 font-bold' : 'text-red-500 font-bold'">
            {{ record.tiempo }}
          </p>

        </div>
        <div slot="estado" class="title-size font-bold text-gray-500">
          Estado
        </div>
        <div slot="status" slot-scope="text, record" class="flex flex-row">
          <p class="w-full rounded-full text-center"
            :class="record.estado == 'Borrador' ? 'text-blue-600 bg-blue-200' : 'text-green-600 bg-green-100'">
            {{ record.estado }}
          </p>

        </div>
        <div slot="actions" class="title-size font-bold text-gray-500">
          Acciones
        </div>
        <div slot="actions" slot-scope="text, record" class="flex flex-row justify-items-center">

          <button class="flex justify-center text-xs mx-auto text-blue h-min"
            @click="goToReportDetail(record.key)">
            <a-icon type="eye" theme="twoTone" two-tone-color="#3366cc"  :style="{ fontSize: '20px' }"/>

          </button>
          <button v-if="idCompany === 1 && record.estado != 'Presentado'" class="flex justify-center text-xs mx-auto text-blue h-min"
            @click="goToReportUpdate(record.key)">
            <a-icon type="edit" theme="twoTone" two-tone-color="#3366cc" :style="{ fontSize: '20px' }" />
          </button>
        </div>
      </a-table>
      <div class="p-4 flex justify-end">
        <a-pagination v-model="page" :page-size="pageSize" :page-size-options="pageSizeOptions" :total="count"
          show-size-changer size="large" />
      </div>
    </div>

  </div>
</template>

<script>
import AnnualReportServices from "@/feature/annual_report/data/services/annual_report_services";
import ErrorMessaje from "@/core/error/error_messaje";
// #004884
const columns = [
  {
    slots: { title: "key" },
    dataIndex: "key",
    key: "key",
    width: 80,
  },
  {
    slots: { title: "fecha_reporte" },
    dataIndex: "fecha_reporte",
    key: "fecha_reporte",
    width: 80,
  },
  {
    slots: { title: "entidad_vigilada" },
    dataIndex: "entidad_vigilada",
    key: "entidad_vigilada",
    width: 80,
  },
  {
    slots: { title: "responsable" },
    dataIndex: "responsable",
    key: "responsable",
    width: 80,
  },
  {
    slots: { title: "tiempo" },
    dataIndex: "tiempo",
    key: "tiempo",
    width: 60,
    ellipsis: true,
    scopedSlots: { customRender: 'tiempo' },
  },
  {
    slots: { title: "estado" },
    dataIndex: "estado",
    key: "estado",
    width: 60,
    ellipsis: true,
    scopedSlots: { customRender: 'status' },
  },
  {
    slots: { title: "actions" },
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
    width: 40,
  },
];


export default {
  components: {},
  data() {
    return {
      loading: false,
      count: 0,
      filterSearch: "",
      page: 1,
      pageSize: 100,
      pageSizeOptions: ["50", "100", "150", "200", "250"],
      columns: columns,
      dataRows: []
    };
  },
  created() {
    this.getReports();
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.updateRouterQuery();
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.getData();
    },
  },
  computed: {
    idCompany: function () {
      return this.$store.state.session?.user?.id_company?.id;
    },
  },
  methods: {
    async getReports() {
      this.loading = true;
      const search = this.filterSearch === "" ? "" : `&search=${this.filterSearch}`;
      console.log(`?page=${this.page}&page_size=${this.pageSize}`)
      const query = `?page=${this.page}&page_size=${this.pageSize}${search}`;
      let response = await AnnualReportServices.listAnualReports(
        query
      );
      console.log('response:::', response)

      if (!response.isSuccess) {
        this.$notification.error({
          message: ErrorMessaje.errorTitle,
          description: response.message,
        });
        this.loading = false;
        return true;
      }

      const data = response.data;
      this.count = data.count;
      this.totalPages = data.total_pages;
      this.dataRows = data.data.map((row) => {
        return {
          key: row.document_id,
          fecha_reporte: row.fecha_reporte,
          entidad_vigilada: row.entidad_vigilada,
          responsable: row.responsable,
          tiempo: row.tiempo,
          estado: row.estado,
          actions: ''
        };
      });

      this.loading = false;
    },
    onSearch(value) {
      this.filterSearch = value;
      this.getReports();
    },
    goToReportCreation() {
      console.log('goToReportCreation')
      this.$router.push({ 'name': 'annual-report-create' })
    },
    goToReportDetail(document_id) {
      console.log('goToReportDetail', document_id)
      this.$router.push({ 'name': 'annual-report-detail', params: { id: document_id } })
      // console.log('goToReportDetail')
      // this.$router.push({ 'name': 'annual-report-detail', params: { id: document_id } }

    },
    goToReportUpdate(document_id) {
      console.log('goToReportUpdate')
      this.$router.push({
        'name': 'annual-report-edit',
        params: { id: document_id },
      }
      )
    },
  },
};
</script>